export type LineHeights = typeof lineHeights

const lineHeights = {
  none: 1,
  s: 1.2,
  s2: 1.25, // custom for Nunito Sans font
  m: 1.4,
  l: 1.6,
} as const

export default lineHeights
