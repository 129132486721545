export interface Fonts {
  system: string
  nunitoSans: string
  montserrat: string
}

const systemFonts =
  'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

const fonts: Fonts = {
  system: systemFonts,
  nunitoSans: `"Nunito Sans", ${systemFonts}`,
  montserrat: `Montserrat, ${systemFonts}`,
}

export default fonts
