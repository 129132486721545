export type Colors = {
  // BASIC
  black: string
  white: string
  transparent: string

  // GRAYS
  gray100: string
  gray200: string
  gray300: string
  gray400: string
  gray500: string
  gray600: string
  gray700: string
  gray800: string
  gray900: string

  // PRIMARY - GLAMO
  primary200: string
  primary300: string
  primary400: string
  primary: string
  primary600: string
  primary700: string

  // ACCENT
  accent200: string
  accent300: string
  accent: string
  accent500: string
  accent600: string
  accent700: string

  // COMMUNICATION
  positive200: string
  positive300: string
  positive: string
  positive500: string
  positive600: string
  positive700: string

  negative200: string
  negative300: string
  negative: string
  negative500: string
  negative600: string
  negative700: string

  warning200: string
  warning300: string
  warning: string
  warning500: string
  warning600: string
  warning700: string

  info200: string
  info300: string
  info: string
  info500: string
  info600: string
  info700: string

  // SOCIAL MEDIAS
  socialFacebookSaturated: string
  socialFacebookDark: string
  socialFacebookText: string
  socialFacebook: string
  socialFacebookLight: string
  socialPinterestDark: string
  socialPinterest: string
}

const colors: Colors = {
  // BASIC
  black: '#000',
  white: '#fff',
  transparent: '#0000',

  // GRAYS
  gray100: '#f9f9f9',
  gray200: '#f4f4f4',
  gray300: '#f0f0f0',
  gray400: '#d6d6d6',
  gray500: '#b3b3b3',
  gray600: '#7f7f7f',
  gray700: '#474747',
  gray800: '#333',
  gray900: '#1f1f1f',

  // TODO: finish darker/lighter colors
  // PRIMARY
  primary200: '#AEC2B2',
  primary300: '#8BA790',
  primary400: '#698C70',
  primary: '#4d6652',
  primary600: '#3D5141',
  primary700: '#2C3A2F',

  // ACCENT
  accent200: '',
  accent300: '',
  accent: '#f2c94c' /* golden yellow */,
  accent500: '',
  accent600: '',
  accent700: '',

  // COMMUNICATION
  positive200: '',
  positive300: '',
  positive: '#27ae60',
  positive500: '',
  positive600: '',
  positive700: '',

  negative200: '',
  negative300: '',
  negative: '#eb5757',
  negative500: '',
  negative600: '',
  negative700: '',

  warning200: '',
  warning300: '',
  warning: '#f2c94c',
  warning500: '',
  warning600: '',
  warning700: '',

  info200: '',
  info300: '',
  info: '#56ccf2',
  info500: '',
  info600: '',
  info700: '',

  // SOCIAL MEDIAS
  socialFacebookSaturated: '#1877f2',
  socialFacebookDark: '#365899',
  socialFacebookText: '#385898',
  socialFacebook: '#4267b2',
  socialFacebookLight: '#a0b3d8',
  socialPinterestDark: '#ad081b',
  socialPinterest: '#e60023',
}

export default colors
