import colors from './colors'

export type Shadows = {
  none: string
  subtle: string
  blurred: string
  floating: string
  glow: string
}

const shadows: Shadows = {
  // TODO: work on site-wide consistent shadows
  none: 'none',
  subtle: '1px 2px 4px 0 rgba(34, 36, 38, 0.15)',
  blurred: '0px 1px 8px 0 rgba(34, 36, 38, 0.15)',
  floating:
    '0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15)',
  glow: `0 0 8px 2px ${colors.primary}99, 0 0 2px 0 ${colors.primary}`,
}

export default shadows
