export interface FontWeights {
  light: number
  regular: number
  medium: number
  semiBold: number
  bold: number
  extraBold: number
  heavy: number
}

const fontWeights: FontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  heavy: 900,
}

export default fontWeights
