export type FontSizes = typeof fontSizes

const fontSizes = {
  xxs: 12,
  xs: 14,
  s: 16,
  m: 18,
  l: 20,
  xl: 24,
  xxl: 32,
  xxxl: 48,
  xxxxl: 64,
} as const

export default fontSizes
