export type Transitions = {
  none: string
  quick: string
  smooth: string
}

const transitions: Transitions = {
  none: '0ms linear',
  quick: '150ms cubic-bezier(0.6, 0.1, 0.45, 0.9)',
  smooth: '400ms cubic-bezier(0.3, 0.3, 0.3, 1)',
}

export default transitions
