exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dekoracje-category-slug-tsx": () => import("./../../../src/pages/dekoracje/[categorySlug].tsx" /* webpackChunkName: "component---src-pages-dekoracje-category-slug-tsx" */),
  "component---src-pages-dekoracje-index-tsx": () => import("./../../../src/pages/dekoracje/index.tsx" /* webpackChunkName: "component---src-pages-dekoracje-index-tsx" */),
  "component---src-pages-dziekujemy-tsx": () => import("./../../../src/pages/dziekujemy.tsx" /* webpackChunkName: "component---src-pages-dziekujemy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jak-dzialamy-tsx": () => import("./../../../src/pages/jak-dzialamy.tsx" /* webpackChunkName: "component---src-pages-jak-dzialamy-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-autorach-strony-tsx": () => import("./../../../src/pages/o-autorach-strony.tsx" /* webpackChunkName: "component---src-pages-o-autorach-strony-tsx" */),
  "component---src-pages-polityka-cookies-tsx": () => import("./../../../src/pages/polityka-cookies.tsx" /* webpackChunkName: "component---src-pages-polityka-cookies-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-regulamin-tsx": () => import("./../../../src/pages/regulamin.tsx" /* webpackChunkName: "component---src-pages-regulamin-tsx" */),
  "component---src-templates-glamo-product-page-tsx": () => import("./../../../src/templates/GlamoProductPage.tsx" /* webpackChunkName: "component---src-templates-glamo-product-page-tsx" */)
}

