export type Space = typeof space

const space = {
  none: 0,
  xxxs: 4,
  xxs: 8,
  xs: 12,
  s: 16,
  m: 20,
  l: 24,
  xl: 32,
  xxl: 48,
  xxxl: 64,
  '4xl': 80,
  '5xl': 96,
} as const

export default space
