import { ThemeProvider } from 'styled-components'

import theme from 'ui/theme/theme'

interface WrapWithProviderProps {
  element: React.ReactNode
}

const WrapWithProvider: React.FC<WrapWithProviderProps> = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
)

export default WrapWithProvider
