export type BreakpointsAliases = {
  mobile: string
  mobileL: string
  tablet: string
  monitorS: string // small laptops, large tablets
  monitor: string // small and regular laptops and monitors
  monitorL: string // widescreen monitors
}

export type Breakpoints = Partial<BreakpointsAliases> & {
  [index: number]: string
}

export type BreakpointsValuesAliases = {
  mobile: number
  mobileL: number
  tablet: number
  monitorS: number // small laptops, large tablets
  monitor: number // small and regular laptops and monitors
  monitorL: number // widescreen monitors
}

export type BreakpointsValues = BreakpointsValuesAliases & {
  [index: number]: number
}

export const breakpointsValues = [375, 480, 768, 992, 1200, 1920] as const

// array of breakpoints values used by styled-system
export const breakpointsBase = breakpointsValues.map(int => `${int}px`)

// array of aliases with corresponding order
export const breakpointsAliases: (keyof BreakpointsAliases)[] = [
  'mobile',
  'mobileL',
  'tablet',
  'monitorS',
  'monitor',
  'monitorL',
]

// object used in devices media queries mapping
export const breakpointsToDevices: BreakpointsAliases = {} as BreakpointsAliases
breakpointsAliases.forEach((breakpoint, index) => {
  breakpointsToDevices[breakpoint] = breakpointsBase[index]
})

// Note: styled-system requires breakpoints to be array-like,
// so we can just extend it with additional fields set on array
//
// Base array:
const breakpoints: Breakpoints = [...breakpointsBase]

// Aliases
breakpoints.mobile = breakpointsBase[0]
breakpoints.mobileL = breakpointsBase[1]
breakpoints.tablet = breakpointsBase[2]
breakpoints.monitorS = breakpointsBase[3]
breakpoints.monitor = breakpointsBase[4]
breakpoints.monitorL = breakpointsBase[5]

export default breakpoints

export const breakpointsNumbers: BreakpointsValues = {
  // Base array
  ...breakpointsValues,
  // Aliases
  mobile: breakpointsValues[0],
  mobileL: breakpointsValues[1],
  tablet: breakpointsValues[2],
  monitorS: breakpointsValues[3],
  monitor: breakpointsValues[4],
  monitorL: breakpointsValues[5],
}
