import breakpoints, { Breakpoints } from './breakpoints'
import colors, { Colors } from './colors'
import devices, { Devices } from './devices'
import fonts, { Fonts } from './fonts'
import fontSizes, { FontSizes } from './fontSizes'
import fontWeights, { FontWeights } from './fontWeights'
import lineHeights, { LineHeights } from './lineHeights'
import shadows, { Shadows } from './shadows'
import space, { Space } from './space'
import transitions, { Transitions } from './transitions'

export type Theme = {
  breakpoints: Breakpoints
  colors: Colors
  devices: Devices
  fonts: Fonts
  fontSizes: FontSizes
  fontWeights: FontWeights
  lineHeights: LineHeights
  shadows: Shadows
  space: Space
  transitions: Transitions
}

const theme: Theme = {
  breakpoints,
  colors,
  devices,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  shadows,
  space,
  transitions,
}

export default theme
