import { breakpointsToDevices, BreakpointsAliases } from './breakpoints'

export type Devices = {
  [breakpoint in keyof BreakpointsAliases]: {
    min: string
    max: string
  }
}

type BreakpointEntry = [
  keyof BreakpointsAliases,
  BreakpointsAliases[keyof BreakpointsAliases]
]

interface CustomObject extends ObjectConstructor {
  entries<K extends keyof BreakpointsAliases, T>(
    o: { [s in K]: T } | ArrayLike<T>
  ): [K, T][]
}

const obj: CustomObject = Object

const devices: Devices = obj
  .entries(breakpointsToDevices)
  .reduce<Devices>((acc, breakpoint: BreakpointEntry) => {
    const [key, value] = breakpoint
    acc[key] = {
      min: `(min-width: ${value})`,
      max: `(max-width: ${value})`,
    }
    return acc
  }, {} as Devices)

export default devices
